/// 判断图片是否存在
export function CheckImgExists (imgUrl: string) {
    return new Promise(function (resolve, reject) {
        const ImgObj = new Image();
        ImgObj.src = imgUrl;
        ImgObj.onload = (res) => {
            resolve(res);
        }
        ImgObj.onerror = (err) => {
            reject(err)
        }
    })
  }