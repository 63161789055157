import request from '@/utils/request'

// 结束流程
export const deleteInstance: any = (instance: any) => {
    return request({
        url: `/instance/deleteInstance`,
        method: 'post',
        data: instance
    })
}

// 获取订单当前任务
export const getCurrentTask: any = (data: any) => {
    return request.post('/bpmn/task/current/biz', data)
  }

// 作废订单
// 结束流程
export const terminateProcess: any = (data: any) => {
    return request({
        url: `/bpmn/terminate/process`,
        method: 'post',
        data: data
    })
}

// 结束订单
export const terminalPatientCase: any = (orderId: string) => {
    return request({
        url: `/patient/case/order/terminalPatientCase/${orderId}`,
        method: 'get',
    })
}